import React, { useEffect } from 'react';
import Routes from './routers/Routes';
// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';

// Modal Video
import 'react-modal-video/scss/modal-video.scss';

// EmailJS
import { init } from '@emailjs/browser';

// Translation
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';

const App = () => {

  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", navigator.language)
    }
    
    const locale = localStorage.getItem("lang");

    let lang;
    if (locale === "en" || locale === "en-US" || locale === "us") {
      lang = "en";
    } else {
      if (locale === "ko" || locale === "ko-KR") {
        lang = "ko";
      }
    }

    i18n.changeLanguage(lang);
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Routes />
    </I18nextProvider>
  );
};

export default App;
