import React from 'react';
import { Link } from 'react-router-dom';

const OpenSourceLogoList = () => {
  const logoNameList = [
    ['grafana', 'prometheus', 'elastic', 'logstash', 'beats'],
    ['kubernetes','nginx','docker-swarm','jenkins'],
    ['docker-compose', 'docker', 'gitlab', 'helm', 'postgresql', 'kafka', 'hadoop'],
    ['openstack', 'nexus', 'ansible', 'harbor', 'chef'],
  ]

  const singleLogoListItem = (logoName) => {
    return (
      <li data-aos='fade-up' data-aos-delay='50'>
        <div 
          className='single-integration'
          style={{
            boxShadow: 'none',
            background: 'transparent'
          }}
        >
          <img
            src={`assets/img/company/${logoName}.svg`}
            alt='integration'
            className='img-fluid'
            style={{
              maxWidth: '180px',
              maxHeight: '100px'
            }}
          />
        </div>
      </li>
    )
  }
  return (
    <section className='support-content'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div 
            className='col-lg-4 col-md-4 d-none d-md-block d-lg-block'
            style={{
              padding: 0
            }}
          >
            <div className='support-article-sidebar sticky-sidebar'>
              <div
                className='nav flex-column nav-pills support-article-tab'
                id='v-pills-support'
                role='tablist'
                aria-orientation='vertical'
              >
                <button
                  className='nav-link fw-bold active'
                  data-bs-target='#support-tab-1'
                  data-bs-toggle='pill'
                  type='button'
                  role='tab'
                  aria-selected='true'
                >
                  Observability & Analysis
                </button>
                <button
                  className='nav-link fw-bold'
                  data-bs-target='#support-tab-2'
                  data-bs-toggle='pill'
                  type='button'
                  role='tab'
                  aria-selected='false'
                >
                  Orchestration
                </button>
                <button
                  className='nav-link fw-bold'
                  data-bs-target='#support-tab-3'
                  data-bs-toggle='pill'
                  type='button'
                  role='tab'
                  aria-selected='false'
                >
                  App definition & Development
                </button>
                <button
                  className='nav-link fw-bold'
                  data-bs-target='#support-tab-4'
                  data-bs-toggle='pill'
                  type='button'
                  role='tab'
                  aria-selected='false'
                >
                  Provisioning
                </button>
              </div>
            </div>
          </div>
          <div className='col-lg-7 col-md-8'>
            <div className='tab-content bg-white rounded-custom' id='v-pills-supportContent'>
              <div
                className='tab-pane fade show active'
                id='support-tab-1'
                role='tabpanel'
              >
                <div className='integration-wrapper'>
                  <ul className='integration-list list-unstyled mb-0'
                    style={{
                      padding: '32px'
                    }}
                  >
                    {
                      logoNameList[0].map(el => {
                        return singleLogoListItem(el);
                      })
                    }
                  </ul>
                </div>
              </div>

              <div
                className='tab-pane fade show'
                id='support-tab-2'
                role='tabpanel'
              >
                <div className='integration-wrapper'>
                  <ul className='integration-list list-unstyled mb-0'
                    style={{
                      padding: '32px'
                    }}
                  >
                    {
                      logoNameList[1].map(el => {
                        return singleLogoListItem(el);
                      })
                    }
                  </ul>
                </div>
              </div>

              <div
                className='tab-pane fade show'
                id='support-tab-3'
                role='tabpanel'
              >
                <div className='integration-wrapper'>
                  <ul className='integration-list list-unstyled mb-0'
                    style={{
                      padding: '32px'
                    }}
                  >
                    {
                      logoNameList[2].map(el => {
                        return singleLogoListItem(el);
                      })
                    }
                  </ul>
                </div>
              </div>

              <div
                className='tab-pane fade show'
                id='support-tab-4'
                role='tabpanel'
              >
                <div className='integration-wrapper'>
                  <ul className='integration-list list-unstyled mb-0'
                    style={{
                      padding: '32px'
                    }}
                  >
                    {
                      logoNameList[3].map(el => {
                        return singleLogoListItem(el);
                      })
                    }
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OpenSourceLogoList;
