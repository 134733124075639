import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../Home/SectionTitle';
import HeroTitle from './DocumentsHeroTitle';
import {randomLinearGradientGenerator} from '../RandomLinearGradientGenerator';

const FeatureTwo = ({ cardDark }) => {
  const featureTextList = [
    {
      title: '오버뷰',
      desc: '오버뷰',
      link: 'overview',
      color: '#51CF66',
      icon: 'gauge'
    },
    {
      title: '클러스터',
      desc: '클러스터 관리',
      link: 'cluster',
      color: '#339AF0',
      icon: 'bezier-curve'
    },
    {
      title: '브로커',
      desc: '브로커 관리',
      link: 'broker',
      color: '#845EF7',
      icon: 'hexagon'
    },
    {
      title: '토픽',
      desc: '토픽 관리',
      link: 'topic',
      color: '#E64980',
      icon: 'folder-grid'
    },
    {
      title: '컨슈머',
      desc: '토픽 관리',
      link: 'consumer',
      color:'#FCC419',
      icon: 'duck'
    },
    {
      title: '클라이언트',
      desc: '토픽 관리',
      link: 'client',
      color: '#FF922B',
      icon: 'universal-access'
    },
    {
      title: '스키마 레지스트리',
      desc: '토픽 관리',
      link: 'schema',
      color: '#22B8CF',
      icon: 'quote-left'
    },
    {
      title: '알림',
      desc: '토픽 관리',
      link: 'alarm',
      color: '#F03E3E',
      icon: 'bell'
    },
    {
      title: '사용자',
      desc: '토픽 관리',
      link: 'user',
      color: '#5C940D',
      icon: 'users'
    },
    {
      title: '설정',
      desc: '토픽 관리',
      link: 'cluster',
      color: '#C0C7D2',
      icon: 'gear'
    },
  ]

  const singleFetureBox = (featureBox) => (
    <div
      className={`feature-card shadow-sm rounded-custom p-5 bg-custom-light promo-border-hover border border-2 border-light text-white`}
      data-aos='fade-up'
      data-aos-delay='50'
    >
      <div
        className='
          icon-box
          d-inline-block
          rounded-circle
          mb-32
        '
        style={{
          background: featureBox.color
        }}
      >
        <i className={`fal fa-${featureBox.icon} icon-sm text-white`}></i>
      </div>
      <div className='feature-content'>
        <h3 className='h3 text-grey-10'>{featureBox.title}</h3>
        {/* <p className='mb-0'>{featureBox.desc}</p> */}
      </div>
      <a
        href={`https://manual.spitha.io/${featureBox.link}/`}
        target="_blank"
        className='link-with-icon text-decoration-none mt-3 text-primary' rel="noreferrer"
      >
        View Details <i className='far fa-arrow-right'></i>
      </a>
    </div>
  )

  return (
    <section
      className={`feature-section ptb-120 bg-white`}
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-10 col-md-10'>
            {cardDark ? (
              <SectionTitle
                subtitle='Services'
                title='Best Services Grow Your Business Value'
                description='Globally actualize cost effective with resource maximizing
                leadership skills.'
                centerAlign
                dark
              />
            ) : (
              <HeroTitle centerAlign/>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='feature-grid'>
              {
                featureTextList.map(el => {
                  return singleFetureBox(el);
                })
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureTwo;
