import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import ContactsForm from './ContactsForm';
import ReviewTwo from './ReviewTabTwo';
import FooterOne from '../Home/Footer/FooterOne';
import Navbar from '../Home/Header/Navbar';
import Layout from '../../layout/Layout';

const RequestDemo = () => {
  return (
    <Layout>
      <PageMeta  title='Live Demo 체험하기 | 가장 쉬운 Apache Kafka® 운영도구, Felice' />
      <Navbar navDark posAbsolute />
      <section
        className='sign-up-in-section bg-dark pb-120'
        style={{
          background:
            "url('assets/img/background/home-gear.svg') no-repeat top",
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <ContactsForm />
            <ReviewTwo reqPage/>
          </div>
        </div>
      </section>
      <FooterOne />
    </Layout>
  );
};

export default RequestDemo;
