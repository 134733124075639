import React from 'react';
import { useTranslation } from 'react-i18next';

const HeroMore = () => {
  const { t } = useTranslation();

  return (
    <section className='customer-review pb-120 bg-primary-light z-10'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 col-md-4'>
            <div
              className='
                review-info-wrap
                text-start
                rounded-custom
                d-flex
                flex-column
                h-100
                p-lg-5
                p-4
              '
              data-aos='fade-up'
              data-aos-delay='50'
            >
              <div className='review-info-content mt-2'>
                <p className='mb-4'>
                  {t('home.heromore.dashboard.desc')}
                </p>
              </div>
              <p
                className='
                  link-with-icon
                  p-0
                  mt-auto
                  text-decoration-none text-primary
                  fw-bold
                '
              >
                {t('home.heromore.dashboard.title')} 
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-4'>
            <div
              className='
                review-info-wrap
                text-start
                rounded-custom
                d-flex
                flex-column
                h-100
                p-lg-5
                p-4
              '
              data-aos='fade-up'
              data-aos-delay='100'
            >
              <div className='review-info-content mt-2'>
                <p className='mb-4'>
                  {t('home.heromore.opensource.desc')} 
                </p>
              </div>
              <p
                className='
                  link-with-icon
                  p-0
                  mt-auto
                  text-decoration-none text-primary
                  fw-bold
                '
              >
                {t('home.heromore.opensource.title')} 
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-4'>
            <div
              className='
                review-info-wrap
                text-start
                rounded-custom
                d-flex
                flex-column
                h-100
                p-lg-5
                p-4
              '
              data-aos='fade-up'
              data-aos-delay='150'
            >
              <div className='review-info-content mt-2'>
                <p className='mb-4'>
                  {t('home.heromore.experience.desc')}
                </p>
              </div>
              <p
                className='
                  link-with-icon
                  p-0
                  mt-auto
                  text-decoration-none text-primary
                  fw-bold
                '
              >
                {t('home.heromore.experience.title')}  
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroMore;
