import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../Home/SectionTitle';
import HeroTitle from './ServicesHeroTitle';

const FeatureTwo = ({ cardDark }) => {
  return (
    <section
      className={`feature-section ptb-120 ${
        cardDark ? 'bg-dark' : 'bg-white'
      }`}
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6 col-md-10'>
            {cardDark ? (
              <SectionTitle
                subtitle='Services'
                title='Best Services Grow Your Business Value'
                description='Globally actualize cost effective with resource maximizing
                leadership skills.'
                centerAlign
                dark
              />
            ) : (
              <HeroTitle
                centerAlign
              />
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='feature-grid'>
              <div
                className={`shadow-sm highlight-card rounded-custom p-5 ${
                  cardDark
                    ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                    : 'bg-white'
                }`}
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <div
                  className='
                    icon-box
                    d-inline-block
                    rounded-circle
                    bg-primary-soft
                    mb-32
                  '
                >
                  <i className='fal fa-analytics icon-sm text-primary'></i>
                </div>
                <div className='feature-content'>
                  <h3 className='h3'>운영 관리 기능</h3>
                  <p>
                    전문적인 기술력이 없이도 손쉽게 Kafka 를 운영할 수 있도록 함으로써 오픈소스 소프트웨어를 도입하는 목적을 달성할 수 있도록 도와드립니다. Felice 를 통하여 Kafka 운영업무에 대한 부담을 덜어버리세요. 
                  </p>
                  <h6 className='mt-4'>Included with...</h6>
                  <ul className='list-unstyled mb-0'>
                    <li className='py-1'>
                      <i
                        className={`fad fa-check-circle me-2 ${
                          cardDark ? 'text-warning' : 'text-primary'
                        }`}
                      ></i>
                      High-converting
                    </li>
                    <li className='py-1'>
                      <i
                        className={`fad fa-check-circle me-2 ${
                          cardDark ? 'text-warning' : 'text-primary'
                        }`}
                      ></i>
                      Personal branding
                    </li>
                    <li className='py-1'>
                      <i
                        className={`fad fa-check-circle me-2 ${
                          cardDark ? 'text-warning' : 'text-primary'
                        }`}
                      ></i>
                      Modernized prospecting
                    </li>
                    <li className='py-1'>
                      <i
                        className={`fad fa-check-circle me-2 ${
                          cardDark ? 'text-warning' : 'text-primary'
                        }`}
                      ></i>
                      Clean and modern
                    </li>
                    <li className='py-1'>
                      <i
                        className={`fad fa-check-circle me-2 ${
                          cardDark ? 'text-warning' : 'text-primary'
                        }`}
                      ></i>
                      Showcasing success
                    </li>
                    <li className='py-1'>
                      <i
                        className={`fad fa-check-circle me-2 ${
                          cardDark ? 'text-warning' : 'text-primary'
                        }`}
                      ></i>
                      Bootstrap latest version
                    </li>
                  </ul>
                </div>
                <Link
                  to='/single-service'
                  className='link-with-icon text-decoration-none mt-3'
                >
                  View Details <i className='far fa-arrow-right'></i>
                </Link>
              </div>
              <div
                className={`feature-card shadow-sm rounded-custom p-5 ${
                  cardDark
                    ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                    : 'bg-white'
                }`}
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <div
                  className='
                    icon-box
                    d-inline-block
                    rounded-circle
                    bg-success-soft
                    mb-32
                  '
                >
                  <i className='fal fa-file-chart-line icon-sm text-success'></i>
                </div>
                <div className='feature-content'>
                  {/* <h3 className='h5'>Automated Reports</h3> */}
                  <h3 className='h3'>통합 모니터링</h3>
                  <p className='mb-0'>
                  별도의 설명이 필요없을 정도로 직관적인 대시보드를 통하여 Kafka 서비스의 운영 상태를 실시간으로 파악할 수 있습니다.
실시간 메트릭 데이터 뿐만 아니라, Felice가 설치 된 노드의 리소스 상태나 사용률을 실시간으로 모니터링하여 대시보드에서 확인 할 수 있도록 하였습니다.
                  </p>
                </div>
                <Link
                  to='/single-service'
                  className='link-with-icon text-decoration-none mt-3'
                >
                  View Details <i className='far fa-arrow-right'></i>
                </Link>
              </div>
              <div
                className={`feature-card shadow-sm rounded-custom p-5 ${
                  cardDark
                    ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                    : 'bg-white'
                }`}
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <div
                  className='
                    icon-box
                    d-inline-block
                    rounded-circle
                    bg-danger-soft
                    mb-32
                  '
                >
                  <i className='fal fa-user-friends icon-sm text-danger'></i>
                </div>
                <div className='feature-content'>
                  <h3 className='h3'>장애 대응</h3>
                  <p className='mb-0'>
                    Felice는 지속적으로 모니터링 데이터를 수집하여, 순간적인 리소스 사용 상황 혹은 장애 현상 등 비정상적인 작동에 대해 다양한 알림을 제공하여 운영자가 즉각적으로 대응할 수 있도록 합니다.
                  </p>
                </div>
                <Link
                  to='/single-service'
                  className='link-with-icon text-decoration-none mt-3'
                >
                  View Details <i className='far fa-arrow-right'></i>
                </Link>
              </div>
              <div
                className={`feature-card shadow-sm rounded-custom p-5 ${
                  cardDark
                    ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                    : 'bg-white'
                }`}
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <div
                  className='
                    icon-box
                    d-inline-block
                    rounded-circle
                    bg-dark-soft
                    mb-32
                  '
                >
                  <i className='fal fa-spell-check icon-sm text-dark'></i>
                </div>
                <div className='feature-content'>
                  <h3 className='h3'>계정 및 권한관리</h3>
                  <p className='mb-0'>
                    Kafka 에서는 제공되지 않는 운영자 계정 관리 기능을 제공하여 운영자별 권한 관리와 작업 히스토리 조회가 가능합니다. 기업 고객이 Kafka 를 운영하기 위해서 필수적으로 요구되는 기능입니다.
                  </p>
                </div>
                <Link
                  to='/single-service'
                  className='link-with-icon text-decoration-none mt-3'
                >
                  View Details <i className='far fa-arrow-right'></i>
                </Link>
              </div>
              <div
                className={`feature-card shadow-sm rounded-custom p-5 ${
                  cardDark
                    ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                    : 'bg-white'
                }`}
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <div
                  className='
                    icon-box
                    d-inline-block
                    rounded-circle
                    bg-warning-soft
                    mb-32
                  '
                >
                  <i className='fal fa-cog icon-sm text-warning'></i>
                </div>
                <div className='feature-content'>
                  <h3 className='h3'>호환성</h3>
                  <p className='mb-0'>
                  Apache Kafka 와의 완벽 Integration
                  </p>
                </div>
                <Link
                  to='/single-service'
                  className='link-with-icon text-decoration-none mt-3'
                >
                  View Details <i className='far fa-arrow-right'></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureTwo;
