import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

// Install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const getTestimonialData = (t) => [
  {
    name: t('home.section.testmonial.customer1.name'),
    responsibility: t('home.section.testmonial.customer1.company'),
    description: t('home.section.testmonial.customer1.desc')
  },
  {
    name: t('home.section.testmonial.customer2.name'),
    responsibility: t('home.section.testmonial.customer2.company'),
    description: t('home.section.testmonial.customer2.desc'),
  },
];

const logoData = [
  { src: 'assets/img/clients/company/HMG.svg' },
  { src: 'assets/img/clients/company/Hyundaiautoever.svg' },
  { src: 'assets/img/clients/company/BCcard.svg' },
  { src: 'assets/img/clients/company/Hanwhalife.svg' },
  { src: 'assets/img/clients/company/CGV.svg' },
  { src: 'assets/img/clients/company/KT.svg' },
  { src: 'assets/img/clients/company/Logen.svg' },
  { src: 'assets/img/clients/company/Douzone.svg' },
  { src: 'assets/img/clients/company/KPX.svg' },
  { src: 'assets/img/clients/company/KERIS.svg' },
  { src: 'assets/img/clients/company/Hyundaiwia.svg' },
  { src: 'assets/img/clients/company/CJolivenetworks.svg' },
  { src: 'assets/img/clients/company/KTds.svg' },
  { src: 'assets/img/clients/company/KTnexr.png' },
  { src: 'assets/img/clients/company/KTtelecop.svg' },
  { src: 'assets/img/clients/company/Klnet.png' },
];

const TestimonialSection = () => {
  const { t } = useTranslation();

  const testimonialData = getTestimonialData(t);

  const singleTestimonial = (person) => (
    <div className='col-lg-5 col-md-12'>
      <div
        className='mb-4 mb-lg-0 mb-xl-0 position-relative bg-white text-decoration-none rounded-custom d-block overflow-hidden p-5'
        style={{
          boxShadow: '0px 5.09714px 24.2114px rgba(2, 11, 48, 0.07)'
        }}
      >
        <div className='position-relative connected-app-content'>
          <h5>{person.name}</h5>
          <p className='text-grey-8'>{person.responsibility}</p>
          <p className='mb-0 text-body'>
            "{person.description}"
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <section 
      className='integration-section ptb-120'
      style={{
        background: '#F8F9FA'
      }}
    >
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12'>
            <div
              className={'section-heading text-center position-relative z-2'}
              data-aos='fade-up'
            >
              <h2 className={`fw-black display-4 text-grey-10`}>
                {t('home.section.testmonial.title')}
              </h2>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          {testimonialData.map((el, index) => singleTestimonial(el, index))}
        </div>
      </div>

      <div className='customer-section pt-80'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12'>
              <Swiper
                spaceBetween={30}
                slidesPerView={6}
                loop={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 20
                  },
                  480: {
                    slidesPerView: 3,
                    spaceBetween: 30
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 40
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 50
                  },
                  1200: {
                    slidesPerView: 6,
                    spaceBetween: 60
                  }
                }}
              >
                {logoData.map((logo, index) => (
                  <SwiperSlide key={index}>
                    <div className="logo-wrapper d-flex justify-content-center align-items-center">
                      <img
                        src={logo.src}
                        alt='clients logo'
                        className='client-logo img-fluid'
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .logo-wrapper {
          height: 36px; // 로고 컨테이너의 높이 설정
          padding: 16px;
        }
        .client-logo {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      `}</style>
    </section>
  );
};

export default TestimonialSection;