import React from 'react';
import SectionTitle from './SectionTitle';
import { useTranslation } from 'react-i18next';

const RealTimeSection = () => {
  const { t } = useTranslation();

  return (
    <section
      className='realtime-section bg-white ptb-120'
    >
      <div className='container'>
        <div className='row justify-content-start align-content-center'>
          <SectionTitle
            title={t('home.section.realtime.title1')}
            subtitle={t('home.section.realtime.title2')}
            description={
              <React.Fragment>
                {t('home.section.realtime.desc1')}
                <br/>
                {t('home.section.realtime.desc2')}
               </React.Fragment>
            }
            centerAlign
          />
        </div>
        <div className='row'>
          <div className='col-12'>
            <img
              src={`assets/img/section/real-time/screen-0.svg`}
              className='img-fluid me-3 rounded'
              width='100%'
              alt={`screen-0`}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default RealTimeSection;
