import React from 'react';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import Navbar from '../Home/Header/Navbar';
import HeroAbout from './HeroAbout';
import OpenSourceSection from './OpenSourceSection';
import ClientSection from './ClientSection';
import CloudServiceSection from './CloudServiceSection';
import TrialSection from '../Home/TrialSection';
import FooterOne from '../Home/Footer/FooterOne';

const About = () => {
  return (
    <Layout>
      <PageMeta title='회사소개 | 가장 쉬운 Apache Kafka® 운영도구, Felice' />
      <Navbar classOption='navbar-light' />
      <HeroAbout />
      <OpenSourceSection />
      <ClientSection />
      <CloudServiceSection />
      <TrialSection />
      <FooterOne footerLight />
    </Layout>
  )
}

export default About;