import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const languageOptions = {
  en: {
    icon: 'assets/img/flags/us_flag.svg',
    label: 'English',
  },
  ko: {
    icon: 'assets/img/flags/kr_flag.svg',
    label: '한국어',
  },
};

const FooterOne = ({ footerLight, style, footerGradient }) => {
  const { i18n, t } = useTranslation();

  const handleChange = async (e) => {
    await i18n.changeLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
    window.location.reload();
  };

  return (
    <footer className='footer-section'>
      <div
        className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${
          footerGradient ? 'bg-gradient' : ''
        }  text-white ptb-120`}
        style={style}
      >
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
              <div className='footer-single-col'>
                <div className='footer-single-col mb-4'>
                  <h3>{t('footer.company.name')}</h3>
                </div>
                <p>
                  {t('footer.company.ceo')}
                  <br />
                  {t('footer.company.address')}
                  <br />
                  <br />
                  {t('footer.company.cs')}: +82-2-791-0500
                  <br />
                  {t('footer.company.email')}: support@spitha.io
                </p>
              </div>
            </div>
            <div className='col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0'>
              <div className='row'>
                <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                  <div className='footer-single-col'>
                    <h3>{t('footer.menu.about')}</h3>
                    <ul className='list-unstyled footer-nav-list mb-lg-0'>
                      <li>
                        <Link to='/' className='text-decoration-none'>
                        {t('navbar.menu.home')}
                        </Link>
                      </li>
                      <li>
                        <Link to='/' className='text-decoration-none'>
                        {t('navbar.menu.company')}
                        </Link>
                      </li>
                      <li>
                        <Link to={{ pathname: "https://medium.com/spitha-techblog" }} target="_blank" className='text-decoration-none'>
                          {t('navbar.menu.blog')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                  <div className='footer-single-col'>
                    <h3>서비스</h3>
                    <ul className='list-unstyled footer-nav-list mb-lg-0'>
                      <li>
                        <Link to='/' className='text-decoration-none'>
                          기능 소개
                        </Link>
                        <li>
                          <Link to='/pricing' className='text-decoration-none'>
                            가격 정책
                          </Link>
                        </li>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                  <div className='footer-single-col'>
                    <h3>{t('footer.menu.contact.title')}</h3>
                    <ul className='list-unstyled footer-nav-list mb-lg-0'>
                      {/* <li>
                        <Link
                          to='/'
                          className='text-decoration-none'
                        >
                          FAQ
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to={process.env.PUBLIC_URL + '/request-for-demo'}
                          className='text-decoration-none'
                        >
                          {t('footer.menu.contact.demo')}
                        </Link>
                      </li>
                      <li>
                        {/* <a href="mailto:support@spitha.io?debby@spitha.io?phjeong@spitha.io&subject=Felice 제품 1:1 문의&body=" className='text-decoration-none'>{t('footer.menu.contact.personal')}</a> */}
                        <a href="mailto:support@spitha.io&subject=Felice 제품 1:1 문의&body=" className='text-decoration-none'>{t('footer.menu.contact.personal')}</a>
                      </li>
                      {/* <li>
                        <Link
                          to='/help-center'
                          className='text-decoration-none'
                        >
                          지원 문의
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                  <div className='footer-single-col'>
                    <select 
                      class="form-select" 
                      aria-label="Language selection" 
                      onChange={handleChange}
                      defaultValue={localStorage.getItem('lang')}
                    >
                      {(Object.keys(languageOptions)).map((language) => {
                        const label = languageOptions[language].label;
                        const icon = languageOptions[language].icon;

                        return (
                          <option
                            key={language}
                            value={language} 
                          >
                            {languageOptions[language].label}
                          </option>
                          )
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`footer-bottom ${
          footerLight ? 'footer-light' : 'bg-dark'
        } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
      >
        <div className='container'>
          <div className='row align-items-center'>
            <div className='copyright-text' style={{ width: '100%' }}>
              <p className='mb-lg-0 mb-md-0'>
                &copy; Copyright - Spitha, Inc. , Apache®, Apache Kafka®, Kafka®, Apache Flink®, Flink®, and associated open source project names are trademarks of the <a href='https://www.apache.org/'>Apache Software Foundation</a>
              </p>
            </div>
            <div className='col-md-4 col-lg-4'>
              <div className='footer-single-col text-start text-lg-end text-md-end'>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

  );
};

export default FooterOne;
