import React from 'react';

const ClientList = () => {
  return (
    <div className='customer-section pb-120 '>
      <div className=''>
        <div className='row justify-content-center'>
          <div className='col-lg-12 col-12'>
            <ul className='customer-logos-grid text-center list-unstyled mb-0'>
              <li>
                <img
                  src='assets/img/clients/company/hyundai-logo.svg'
                  width='150'
                  alt='clients logo'
                  className='img-fluid
                  customer-logo-gray
                  p-1 p-md-2 p-lg-3 m-auto'
                  data-aos='fade-up'
                />
              </li>
              <li>
                <img
                  src='assets/img/clients/company/hyundai-autoever-logo.svg'
                  width='150'
                  alt='clients logo'
                  className='img-fluid customer-logo-gray p-1 p-md-2 p-lg-3 m-auto'
                  data-aos='fade-up'
                  data-aos-delay='50'
                />
              </li>
              <li>
                <img
                  src='assets/img/clients/company/posco-ict-logo.svg'
                  width='150'
                  alt='clients logo'
                  className='img-fluid customer-logo-gray p-1 p-md-2 p-lg-3 m-auto'
                  data-aos='fade-up'
                  data-aos-delay='100'
                />
              </li>
              <li>
                <img
                  src='assets/img/clients/company/daerim-logo.svg'
                  width='150'
                  alt='clients logo'
                  className='img-fluid customer-logo-gray p-1 p-md-2 p-lg-3 m-auto'
                  data-aos='fade-up'
                  data-aos-delay='150'
                />
              </li>
              <li>
                <img
                  src='assets/img/clients/company/kt-tech-logo.svg'
                  width='150'
                  alt='clients logo'
                  className='img-fluid customer-logo-gray p-1 p-md-2 p-lg-3 m-auto'
                  data-aos='fade-up'
                  data-aos-delay='200'
                />
              </li>
              <li>
                <img
                  src='assets/img/clients/company/sampyo-logo.svg'
                  width='150'
                  alt='clients logo'
                  className='img-fluid customer-logo-gray p-1 p-md-2 p-lg-3 m-auto'
                  data-aos='fade-up'
                  data-aos-delay='250'
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientList;
