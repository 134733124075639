import React from 'react';
import FeatureTwo from './FeatureTwo';
import FooterOne from '../Home/Footer/FooterOne';
import Navbar from '../Home/Header/Navbar';
import Layout from '../../layout/Layout';

const Services = () => {
  return (
    <Layout>
      <Navbar classOption='navbar-light' />
      <FeatureTwo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default Services;
