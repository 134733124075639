import React from 'react';
import { useTranslation } from 'react-i18next';

const HeroTitle = ({ title, desc }) => {
  const { t } = useTranslation();

  return (
    <div>
      <h1 className='fw-black display-2 text-white' data-aos='fade-up'>
        <span style={{
          color: 'rgba(255, 255, 255, 0.5)'
        }}>{t('home.herotitle.title1')}</span>
        <br />
        {t('home.herotitle.title2')}
      </h1>
      <p className='lead text-white' style={{
        marginTop: '28px',
        fontWeight: 600
      }} data-aos='fade-up' data-aos-delay='50'>
        {t('home.herotitle.desc1')} <br/>
        {t('home.herotitle.desc2')}
      </p>
    </div>
  );
};

export default HeroTitle;
