import React from 'react';

const HeroTitle = ({ title, desc, centerAlign }) => {
  return (
    <React.Fragment>
      <h1 data-aos='fade-up'
      className={`fw-black display-2 text-grey-10 ${
        centerAlign ? 'section-heading text-center' : 'section-heading'
      }`}>
        구성요소 관리 기능 설명서
      </h1>
      <p className='lead text-white' style={{
        marginTop: '28px',
        fontWeight: 600
      }} data-aos='fade-up' data-aos-delay='50'>
        {desc}
      </p>
    </React.Fragment>
  );
};

export default HeroTitle;
