import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../pages/Home/Home';
import About from '../pages/About/About';
import Services from '../pages/Services/Services';
import Documents from '../pages/Documents/Documents';
import Blogs from '../pages/Blog/Blogs';
import RequestDemo from '../pages/RequestDemo/RequestDemo';
import RequestSuccessPage from '../pages/RequestDemo/RequestSuccessPage';
import NotFoundScreen from '../components/others/NotFoundScreen';
import ScrollToTop from '../components/common/ScrollToTop';

export default () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        {/* Spitha */}
        <Route exact path='/' component={Home} />
        <Route exact path='/about' component={About} />
        <Route exact path='/services' component={Services} />
        <Route exact path='/documents' component={Documents} />
        {/* <Route path='/blogs' component={() => {
          window.location.href = 'https://medium.com/spitha-techblog';
          return null;
        }}/> */}
        <Route exact path='/request-for-demo' component={RequestDemo} />
        <Route exact path='/success' component={RequestSuccessPage} />
        <Route component={NotFoundScreen} />
      </Switch>
    </Router>
  );
}
