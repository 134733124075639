import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs, { init } from '@emailjs/browser';
import { Link } from 'react-router-dom';

const ContactsForm = () => {
  const { t } = useTranslation();
  
  useEffect(() => {
    init('HKYn6v4xI4RQJh-Nc');
  }, []);

  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState(false);
  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    setIsLoading(true);

    emailjs.sendForm('service_t1twa1f', 'template_qsrvg3r', form.current, 'HKYn6v4xI4RQJh-Nc')
      .then((result) => {
        setIsLoading(false);
        window.location.assign('/success');
      }, (error) => {
        setIsLoading(false);
        console.log(error.text);
      });
  };


  return (
    <React.Fragment>
      <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1'>
        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h2 className='fw-medium h4'>
            {t('demo.form.title')}
          </h2>

          <form className='mt-4 register-form' ref={form} onSubmit={sendEmail}>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    name='emailjs-name'
                    className='form-control'
                    placeholder={`${t('demo.form.placeholder.name')}*`}
                    aria-label='name'
                  />
                </div>
              </div>
              <div className='col-sm-12'>
                <div className='input-group mb-3'>
                  <input
                    type='email'
                    name="emailjs-email"
                    className='form-control'
                    placeholder={`${t('demo.form.placeholder.email')}*`}
                    aria-label='email'
                  />
                </div>
              </div>

              <div className='col-sm-12'>
                <div className='input-group mb-3'>
                  <input
                    type='email'
                    name="emailjs-company_email"
                    className='form-control'
                    placeholder={`${t('demo.form.placeholder.workemail')}`}
                    aria-label='work-Email'
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='input-group mb-3'>
                  <textarea
                    className='form-control'
                    name="message"
                    placeholder={t('demo.form.placeholder.desc')}
                    style={{ height: '120px' }}
                  ></textarea>
                </div>
              </div>
              <div className='col-12'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={e => (
                      console.log(e.target.checked),
                      setIsChecked(e.target.checked)
                    )}
                    id='flexCheckChecked'
                    checked={isChecked}
                  />
                  <label
                    className='form-check-label small'
                    htmlFor='flexCheckChecked'
                  >
                    {t('demo.form.placeholder.policy')}
                    <Link to='#'className='text-primary' style={{
                      textDecoration: 'none'
                    }}> View privacy policy</Link>.
                  </label>
                </div>
              </div>
              <div className='col-12'>
                <button
                  type='submit'
                  className='btn btn-primary mt-4 d-block w-100'
                  disabled={!isChecked || isLoading}
                >
                  {
                    isLoading && (
                      <React.Fragment>
                        <div class="spinner-grow text-light spinner-grow-sm" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        {' '}
                      </React.Fragment>
                    )
                  }
                  {t('demo.form.button')}
                </button>
              </div>
            </div>
          </form>
        </div>

       
      </div>
    </React.Fragment>
  );
};

export default ContactsForm;
