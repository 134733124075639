import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeroTitle from './HeroTitle';

const HeroTwo = () => {
  const { t } = useTranslation();

  return (
    <section
      className='hero-section ptb-120 text-white bg-primary-gradient'
      style={{
        position: 'relative'
      }}
    >
      <div className='container'>
        <div className='row justify-content-center text-center text-lg-start align-items-center'>
          <div className='col-xl-12  col-lg-12 mb-4 '
          style={{
            marginTop: '5rem'
          }}>
            <div className='hero-content-wrap'>
              <HeroTitle />
              <div
                className='action-btns text-center text-lg-start align-items-center pt-4'
                data-aos='fade-up'
                data-aos-delay='100'
              >
                <Link to='/request-for-demo' className='btn btn-light me-3'>
                  {t('home.hero.button')}
                </Link>
              </div>
            </div>
          </div>
          <div className='col-lg-9'>
            <div
              className='position-relative'
              data-aos='fade-up'
              data-aos-delay='200'
            >
              <ul
                className='
            position-absolute
            animate-element
            parallax-element
            widget-img-wrap
            z-5
          '
              >
                <li className='layer' data-depth='0.04'>
                  <img
                    src='assets/img/dashboard/main-1.svg'
                    alt='widget-img'
                    className='
                img-fluid
                widget-img-1
                position-absolute
                rounded-custom
              '
                  />
                </li>
                <li className='layer' data-depth='0.02'>
                  <img
                    src='assets/img/dashboard/main-2.svg'
                    alt='widget-img'
                    className='
                img-fluid
                widget-img-2
                position-absolute
                rounded-custom
              '
                  />
                </li>
                <li className='layer' data-depth='0.02'>
                  <img
                    src='assets/img/dashboard/main-3.svg'
                    alt='widget-img'
                    className='
                img-fluid
                widget-img-3
                position-absolute
                rounded-custom
              '
                  />
                </li>
              </ul>
              <img
                src='assets/img/dashboard/main-0.svg'
                alt=''
                className='img-fluid position-relative rounded-custom mt-5 z-2'
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className='
          bg-primary-light
          position-absolute
          h-25
          left-0
          right-0
          z-1
          py-5
        '
        style={{
          bottom: 0
        }}
      ></div>
    </section>
  );
};

export default HeroTwo;
