import React from 'react';

const SectionTitle = ({ title, subtitle, description, bgDark }) => {
  return (
    <div
      className={'section-heading'}
      data-aos='fade-up'
    >
      <h2 className={`fw-black display-4 ${bgDark ? 'text-white' : 'text-grey-10'}`}>
        {title}
        <br />
        <span
          style={{
            color: bgDark ? 'rgba(255, 255, 255, 0.5':'text-grey-6'
          }}
        >{subtitle}</span>
      </h2>
      {
        description && (
          <p className={`lead ${bgDark ? 'text-white' : 'text-grey-7'}`} style={{
            marginTop: '28px',
            fontWeight: 600,
          }} data-aos='fade-up' data-aos-delay='50'>
            {description}
          </p>
        )
      }
    </div>
  );
};

export default SectionTitle;