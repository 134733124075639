import React from 'react';
import { useTranslation } from 'react-i18next';
import ClientList from './ClientList';
import SectionTitle from '../Home/SectionTitle';

const RealTimeSection = () => {
  const { t } = useTranslation();

  return (
    <section
      className='realtime-section bg-white ptb-120'
    >
      <div className='container'>
        <div className='row justify-content-start align-content-center'>
          <SectionTitle
            title={t('company.section.client.title1')}
            subtitle={t('company.section.client.title2')}
            description={
              <React.Fragment>
                {t('company.section.client.desc1')}
                <br/>
                {t('company.section.client.desc2')}
              </React.Fragment>
            }
            centerAlign
          />
        </div>
        <div className='row'>
          <div className='col-12'>
            <ClientList />
          </div>
        </div>
      </div>
    </section>
  )
}

export default RealTimeSection;
