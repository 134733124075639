import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import ComingSoonScreen from './ComingSoonScreen';
import Layout from '../../layout/Layout';

const RequestSuccessPage = () => {
  return (
    <Layout>
      <PageMeta title='Thank you! | 가장 쉬운 Apache Kafka® 운영도구, Felice' />
      <ComingSoonScreen />
    </Layout>
  );
};

export default RequestSuccessPage;
