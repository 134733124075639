import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeroTitle from './HeroAboutTitle';

const HeroAbout = () => {
  const { t } = useTranslation();

  return (
    <section
      className='hero-section bg-white'
      style={{
        position: 'relative',
        height: '100vh',
      }}
    >
      <div className='container'>
        <div className='row align-items-center justify-content-lg-between'>
          <div className='col-lg-6 col-md-10'
            style={{
              marginTop: '5rem'
            }}>
              <div className='hero-content-wrap mt-5 mt-lg-0 mt-xl-0'>
                <HeroTitle
                  desc={t('company.desc')}
                />
                <div
                  className='action-btns text-center text-lg-start align-items-center pt-4'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >
                  <Link to='/request-for-demo' className='btn btn-primary me-3'>
                  {t('company.button')}
                  </Link>
                </div>
              </div>
          </div>
          
          <div className='col-lg-6 col-md-8'>
            <div
              className='hero-img-wrap position-relative app-screen-bg mt-5'
              data-aos='fade-up'
              data-aos-delay='200'
            >
              <img
                src='assets/img/dashboard/about-0.svg'
                alt='hero'
                className='img-fluid position-relative z-5'
              />
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default HeroAbout;