import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE_FOR_OS } from './types';
import localeEN from './lang/en.json';
import localeKO from './lang/kr.json';

const resources = {
  [LANGUAGE_FOR_OS.EN]: { translation: localeEN },
  [LANGUAGE_FOR_OS.KO]: { translation: localeKO }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: {
      'ko': ['ko', 'ko-KR'],
      'en': ['en', 'en-US'],
      'default': ['en', 'en-US']
    },
    interpolation: {
      escapeValue: false
    },
  });

export default i18n;