import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionTitle from '../Home/SectionTitle';
import OpenSourceLogoList from './OpenSourceLogoList';

const OpenSourceSection = () => {
  const { t } = useTranslation();

  return (
    <section
      className='realtime-section bg-primary-light ptb-120'
    >
      <div className='container'>
        <div className='row justify-content-start align-content-center'>
          <SectionTitle
            title={t('company.section.opensource.title1')}
            subtitle={t('company.section.opensource.title2')}
            description={
              <React.Fragment>
                {t('company.section.opensource.desc1')}
                <br/>
                {t('company.section.opensource.desc2')}
              </React.Fragment>
            }
            centerAlign
          />
        </div>
        <OpenSourceLogoList />
      </div>
    </section>
  )
}

export default OpenSourceSection;
