import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OffCanvasMenu from './OffCanvasMenu';

const Navbar = ({ navDark, navTransparent }) => {
  const { t } = useTranslation();

  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector('.main-header');
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <div>
      <header
        // className={`main-header ${navDark ? 'position-absolute' : ''} w-100 `}
        className={`main-header ${navTransparent ? 'position-absolute' : ''} w-100 `}
        style={{
          zIndex: 9999,
          background: (navDark && 'rgb(19, 25, 54)') || (navTransparent && 'none')
        }}
      >
        <nav
          className={`navbar navbar-expand-xl ${
            ((navDark || navTransparent ) ? 'navbar-dark' : 'navbar-light')
          } sticky-header ${scroll > headerTop ? 'affix' : ''}`}
        >
          <div className='container d-flex align-items-center justify-content-lg-between position-relative'>
            <Link
              to={process.env.PUBLIC_URL + '/'}
              className='navbar-brand d-flex align-items-center mb-md-0 text-decoration-none'
            >
              <img
                src='assets/img/logo/logo.svg'
                alt='logo'
                className='img-fluid logo-white'
              />
              <img
                src='assets/img/logo/logo-color.svg'
                alt='logo'
                className='img-fluid logo-color'
              />
            </Link>

            <Link
              className='navbar-toggler position-absolute right-0 border-0'
              to={process.env.PUBLIC_URL + '#offcanvasWithBackdrop'}
              role='button'
            >
              <span
                className='far fa-bars'
                data-bs-toggle='offcanvas'
                data-bs-target='#offcanvasWithBackdrop'
                aria-controls='offcanvasWithBackdrop'
              ></span>
            </Link>

            <div className='collapse navbar-collapse justify-content-left'>
              <ul className='nav col-12 col-md-auto justify-content-center main-menu'>
                <li>
                  <Link
                    to={process.env.PUBLIC_URL + '/'}
                    className='nav-link'
                  >
                    {t('navbar.menu.home')}
                  </Link>
                </li>
                <li>
                  <Link
                    to={process.env.PUBLIC_URL + '/about'}
                    className='nav-link'
                  >
                    {t('navbar.menu.company')}
                  </Link>
                </li>

                {/* [DEVELOP]
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + '/services'}
                      className='nav-link'
                    >
                      서비스
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + '/documents'}
                      className='nav-link'
                    >
                      설명서
                    </Link>
                  </li> 
                */}
                <li>
                  <Link
                    to={{ pathname: "https://medium.com/spitha-techblog" }} target="_blank"
                    className='nav-link'
                  >
                    {t('navbar.menu.blog')}
                  </Link>
                </li>
              </ul>
            </div>

            <div className='action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block'>
              {/* <Link
                to={process.env.PUBLIC_URL + '/login'}
                className='btn btn-link text-decoration-none me-2'
              >
                로그인
              </Link> */}
              <Link
                to={process.env.PUBLIC_URL + '/request-for-demo'}
                className={`btn btn-${scroll > headerTop ? 'primary' : (
                  (navDark || navTransparent )  ? 'light' : 'primary'
                )}`}
              >
                {t('navbar.button')}
              </Link>
            </div>
            <OffCanvasMenu classOption='navbar-light'/>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
