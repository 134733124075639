import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper/core';
import SectionTitle from './SectionTitle';

const NUM_TOTAL_SLIDES = 5;

const getArrSlideTitle = (t) => [
  t('home.section.slider.tab1'),
  t('home.section.slider.tab2'),
  t('home.section.slider.tab3'),
  t('home.section.slider.tab4'),
  t('home.section.slider.tab5'),
]

SwiperCore.use([Autoplay, Pagination]);

const SliderSection = () => {
  const { t } = useTranslation();

  const arrSlideTitle = getArrSlideTitle(t);

  const swiperOption = {
    slidesPerView: 1,
    mousewheel: true,
    slidesPerGroup: 1,
    loop: true,
    pagination: {
      clickable: true,
      el: '.swiper-pagination',
      renderBullet: function (index, className) {
        return `<span class="${className} nav-item fw-bold" style="color: #0065FF !important; font-size: 1.25rem;">${arrSlideTitle[index] || ''}</span>`;
      },
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  };

  return (
    <>
      <style>
        {`
          .swiper-pagination-bullet {
            width: auto;
            height: auto;
            background: none;
            text-align: center;
            line-height: 20px;
            margin: 0 10px; // 각 항목 사이에 약간의 간격 추가
          }
        `}
      </style>
      <section className='testimonial-section bg-white ptb-120'>
        <div className='container'>
          <div className='row justify-content-start align-content-center'>
            <div className='col-md-12 col-lg-12'>
              <SectionTitle
                title={t('home.section.slider.title1')}
                subtitle={t('home.section.slider.title2')}
                description={
                  <React.Fragment>
                    {t('home.section.slider.desc1')}
                    <br />
                    {t('home.section.slider.desc2')}
                  </React.Fragment>
                }
                centerAlign
              />
            </div>
          </div>

          <div className='row' style={{ marginBottom: '40px' }}>
            <div className='col-12'>
              <div className="swiper-pagination"></div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className='position-relative'>
                <Swiper {...swiperOption}>
                  {[...Array(NUM_TOTAL_SLIDES)].map((v, index) =>
                    <SwiperSlide key={index}>
                      <img
                        src={`assets/img/slider/screen-${index}.svg`}
                        className='img-fluid me-3 rounded'
                        width='100%'
                        alt={`screen-${index}`}
                      />
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SliderSection;
