import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ModalVideo from 'react-modal-video';
import SectionTitle from './SectionTitle';

const TrialSection = () => {
  const { t } = useTranslation();

  return (
    <section 
      className='cta-subscribe ptb-150 position-relative overflow-hidden'
      style={{
        background: 'linear-gradient(289.63deg, #39ACF3 9.39%, #008BFC 35.3%, #0064FF 66.92%, #002DFF 93.71%)'
      }}
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-12 col-md-12'>
            <div className='subscribe-info-wrap text-center position-relative z-2'>
              <SectionTitle
                title={t('home.section.trial.title')}
                description={
                  <ul
                    className='nav justify-content-center subscribe-feature-list mt-4 text-white'
                    data-aos='fade-up'
                    data-aos-delay='100'
                  >
                    <li className='nav-item'>
                      <span>
                        <i className='far fa-check-circle text-white me-2'></i>
                        {t('home.section.trial.feature1')}
                      </span>
                    </li>
                    <li className='nav-item'>
                      <span>
                        <i className='far fa-check-circle text-white me-2'></i>
                        {t('home.section.trial.feature2')}
                      </span>
                    </li>
                    <li className='nav-item'>
                      <span>
                        <i className='far fa-check-circle text-white me-2'></i>
                        {t('home.section.trial.feature3')}
                      </span>
                    </li>
                  </ul>
                }
                bgDark
              />
              <div className='form-block-banner mw-60 m-auto mt-5'>
                {/* CtaTwo - ModalVideo 버튼 css참고: hover */}
                <Link to='/request-for-demo' className='btn btn-light'>
                {t('home.section.trial.button')}
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className='
            bg-circle
            rounded-circle
            circle-shape-3
            position-absolute
            bg-dark-light
            left-5
          '
        ></div>
        <div
          className='
            bg-circle
            rounded-circle
            circle-shape-1
            position-absolute
            bg-warning
            right-5
          '
        ></div> */}
      </div>
    </section>
  );
};

export default TrialSection;
