import React from 'react';
import { useTranslation } from 'react-i18next';

const HeroTitle = ({ title, desc }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <h1 className='fw-black display-3 text-primary' data-aos='fade-up'>
        {/* --bs-grey-10 */}
        <div style={{
          color: '#121D2E'
        }}>{t('company.title1')}</div>
        {t('company.title2')}
      </h1>
      <p className='lead text-white' style={{
        marginTop: '28px',
        fontWeight: 600
      }} data-aos='fade-up' data-aos-delay='50'>
        {desc}
      </p>
    </React.Fragment>
  );
};

export default HeroTitle;
