import React from 'react';
import Navbar from './Header/Navbar';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from './Footer/FooterOne';
import Hero from './Hero';
// import HeroMore from './HeroMore';
import HeroMoreWithoutLink from './HeroMoreWithoutLink';
import SliderSection from './SliderSection';
import RealTimeSection from './RealTimeSection';
import TestmonialSection from './TestmonialSection';
import PremiumServiceSection from './PremiumServiceSection';
import TrialSection from './TrialSection';
import TestimonialThree from '../../components/testimonial/TestimonialThree';

const Home = () => {
  return (
    <Layout>
      <PageMeta title='가장 쉬운 Apache Kafka® 운영도구, Felice' />
      <Navbar navTransparent />
      <Hero />
      <HeroMoreWithoutLink />
      <SliderSection bgWhite/>
      <RealTimeSection />
      <TestmonialSection />
      <PremiumServiceSection />
      <TrialSection />
      <FooterOne footerLight />
    </Layout>
  );
};

export default Home;
