import React from 'react';
import BlogGrid from './BlogGrid';
import PageHeader from './PageHeader';
import PageMeta from '../../components/common/PageMeta';
import Navbar from '../Home/Header/Navbar';
import FooterOne from '../Home/Footer/FooterOne';
import Layout from '../../layout/Layout';

const Blogs = () => {
  return (
    <Layout>
      <PageMeta title='블로그 | 가장 쉬운 Apache Kafka 운영도구, Felice' />
      <Navbar classOption='navbar-light' />
      <PageHeader
        title='블로그'
        desc='Felice 공유 기술 블로그입니다.'
        blogtags
      />
      <BlogGrid />
      <FooterOne />
    </Layout>
  );
};

export default Blogs;
