import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionTitle from './SectionTitle';

const PremiumServiceSection = () => {
  const { t } = useTranslation();

  return (
    <section 
      className='premium-service-section ptb-150'
      style={{
        backgroundColor: '#020B30',
        backgroundImage: 'url(assets/img/background/section-premium.svg)',
        backgroundSize: 'cover',
        width: '100%',
      }}
    >
      <div className='container'>
        <div className='row justify-content-lg-between align-items-center'>
          <div className='col-lg-12 col-12'>
            <SectionTitle
              title={t('home.section.premium.title1')}
              subtitle={t('home.section.premium.title2')}
              description={
                <React.Fragment>
                  {t('home.section.premium.desc')}
                </React.Fragment>
              }
              bgDark
              centerAlign
            />

            <ul className='list-unstyled mt-4 mb-4 text-white'>
              <li className='py-1'>
                <i className='fas fa-check-circle me-3 text-white'></i>
                {t('home.section.premium.feature1')}
              </li>
              <li className='py-1'>
                <i className='fas fa-check-circle me-3 text-white'></i>
                {t('home.section.premium.feature2')}
              </li>
              <li className='py-1'>
                <i className='fas fa-check-circle me-3 text-white'></i>
                {t('home.section.premium.feature3')}
              </li>
              <li className='py-1'>
                <i className='fas fa-check-circle me-3 text-white'></i>
                {t('home.section.premium.feature4')}
              </li>
            </ul>
            {/* <Link
              to='/about-us'
              className='read-more-link text-decoration-none'
            >
              Know More About Us <i className='far fa-arrow-right ms-2'></i>
            </Link> */}
          </div>         
        </div>
      </div>
    </section>
  );
};

export default PremiumServiceSection;
