
import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionTitle from '../Home/SectionTitle';

const CloudServiceSection = () => {
  const { t } = useTranslation();

  return (
    <section 
      className='cloud-service-section ptb-150'
      style={{
        backgroundColor: '#020B30',
        backgroundImage: 'url(assets/img/background/section-cloud.svg)',
        backgroundSize: 'cover',
        width: '100%',
      }}
    >
      <div className='container'>
        <div className='row justify-content-lg-between align-items-center'>
          <div className='col-lg-12 col-12'>
            <SectionTitle
              title={t('company.section.cloud.title')}
              description={t('company.section.cloud.desc')}
              bgDark
              centerAlign
            />

            <ul className='list-unstyled mt-4 mb-4 text-white'>
              <li className='py-1'>
                <i className='fas fa-check-circle me-3 text-white'></i>
                {t('company.section.cloud.feature1')}
              </li>
              <li className='py-1'>
                <i className='fas fa-check-circle me-3 text-white'></i>
                {t('company.section.cloud.feature2')}
              </li>
              <li className='py-1'>
                <i className='fas fa-check-circle me-3 text-white'></i>
                {t('company.section.cloud.feature3')}
              </li>
              <li className='py-1'>
                <i className='fas fa-check-circle me-3 text-white'></i>
                {t('company.section.cloud.feature4')}
              </li>
            </ul>
            {/* <Link
              to='/about-us'
              className='read-more-link text-decoration-none'
            >
              Know More About Us <i className='far fa-arrow-right ms-2'></i>
            </Link> */}
          </div>         
        </div>
      </div>
    </section>
  );
};

export default CloudServiceSection;
