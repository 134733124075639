import React from 'react';
import FeatureTwo from './FeatureTwo';
import FooterOne from '../Home/Footer/FooterOne';
import Navbar from '../Home/Header/Navbar';
import Layout from '../../layout/Layout';

const Documents = () => {
  return (
    <Layout>
      <Navbar />
      <FeatureTwo />
      <FooterOne />
    </Layout>
  );
};

export default Documents;
