import React from 'react';
import { Link } from 'react-router-dom';

const ComingSoonScreen = () => {
  return (
    <section
      className='coming-soon-section min-vh-100 ptb-120 overflow-hidden position-relative w-100 d-flex flex-column justify-content-center'
      // style={{
      //   background:
      //     "url('assets/img/page-header-bg.svg')no-repeat bottom right",
      // }}
    >
      <div className='bg-dark fixed-bg'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-5 col-12'>
            <div className='coming-soon-content-wrap position-relative z-2'>
              <Link to='/' className='mb-5 d-block'>
                <img
                  src='assets/img/logo/logo.svg'
                  alt='logo'
                  className='img-fluid'
                />
              </Link>

              {/* <h5 className='text-white'>Please check your email.</h5> */}
              <h5 className='text-white'>이메일을 확인해 주세요.</h5>
              <h1 className='fw-black text-white display-4'>
                Thank you!
              </h1>
              <div className='action-btns'>
                <Link
                  to='/'
                  className='btn btn-primary mt-5 popup-with-form'
                >
                  홈으로 돌아가기
                </Link>
              </div>
              
            </div>
          </div>
        </div>

        <ul className='animated-circle list-unstyled z--1'>
          <li className='transition-delay-1' style={{
            backgroundColor: '#AE3EC9'
          }}></li>
          <li className='transition-delay-2 bg-warning'></li>
          <li className='transition-delay-3 bg-primary'></li>
        </ul>
      </div>
    </section>
  );
};

export default ComingSoonScreen;
