import React from 'react';
import { useTranslation } from 'react-i18next';

const ReviewTwo = ({ reqPage }) => {
  const { t } = useTranslation();

  return (
    <div className='col-xl-5 col-lg-5 col-md-12 order-1 order-lg-0'>
      <div className='testimonial-tab-slider-wrap mt-5'>
        {reqPage ? (
          <h1 className='fw-black text-white display-4'>
            {t('demo.title')}
          </h1>
        ) : (
          <h2 className='text-white'>Start Your Project with Us</h2>
        )}
        <p>
          {t('demo.desc')}
        </p>
          <ul className='list-unstyled mt-4 mb-4 text-white'>
              <li className='py-1'>
                <i className='fas fa-check-circle me-3 text-white'></i>
                {t('demo.feature1')}
              </li>
              <li className='py-1'>
                <i className='fas fa-check-circle me-3 text-white'></i>
                {t('demo.feature2')}
              </li>
              <li className='py-1'>
                <i className='fas fa-check-circle me-3 text-white'></i>
                {t('demo.feature3')}
              </li>
            </ul>
        </div>
    </div>
  );
};

export default ReviewTwo;
