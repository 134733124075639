import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OffCanvasMenu = () => {
  const { t } = useTranslation();

  return (
    <div
      className='offcanvas offcanvas-end'
      tabIndex='-1'
      id='offcanvasWithBackdrop'
    >
      <div className='offcanvas-header d-flex align-items-center mt-4'>
        <Link
          to='/'
          className='d-flex align-items-center mb-md-0 text-decoration-none'
        >
          <img
            src='assets/img/logo/logo-color.svg'
            alt='logo'
            className='img-fluid ps-2'
          />
        </Link>
        <button
          type='button'
          className='close-btn text-danger'
          data-bs-dismiss='offcanvas'
          aria-label='Close'
        >
          <i className='far fa-close'></i>
        </button>
      </div>
      <div className='offcanvas-body'>
        <ul className='nav col-12 col-md-auto justify-content-center main-menu'>
          <li>
            <Link
              to={process.env.PUBLIC_URL + '/'}
              className='nav-link'
            >
              {t('navbar.menu.home')}
            </Link>
          </li>
          <li>
            <Link
              to={process.env.PUBLIC_URL + '/about'}
              className='nav-link'
            >
              {t('navbar.menu.company')}
            </Link>
          </li>
          {/* [DEVELOP]
            <li>
              <Link
                to={process.env.PUBLIC_URL + '/services'}
                className='nav-link'
              >
                서비스
              </Link>
            </li>
            <li>
              <Link
                to={process.env.PUBLIC_URL + '/documents'}
                className='nav-link'
              >
                설명서
              </Link>
            </li> 
          */}
          <li>
            <Link
              to={{ pathname: "https://medium.com/spitha-techblog" }} target="_blank"
              className='nav-link'
            >
              {t('navbar.menu.blog')}
            </Link>
          </li>

        </ul>
        <div className='action-btns mt-4 ps-3'>
          <Link to='/request-for-demo' className='btn btn-primary'>
            시작하기
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OffCanvasMenu;
